import ToastOptions from "@/utils/handlers/toastHandler/ToastOptions";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import {pluralizeRegularString} from "@/utils/globals";
import router from "@/router";

class ToastContent {
  constructor({title, subtitle, icon, options, id = ToastHandler.generateToastId}) {
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.icon = icon;
    this.options = options;
  }
}

export const TOAST_CONTENTS = {

  /** Project **/
  PROJECT_CREATED: (name) => new ToastContent({
    title: 'Success',
    subtitle: `The project <strong>'${name}'</strong> has been created.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  PROJECT_DELETED: (name) => new ToastContent({
    title: 'Success',
    subtitle: `The project <strong>'${name}'</strong> has been deleted from your projects.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  PROJECT_EDITED: (name) => new ToastContent({
    title: 'Success',
    subtitle: `The project <strong>'${name}'</strong> has been edited.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  PROJECT_DELETE_UNALLOWED: new ToastContent({
    title: 'Not allowed',
    subtitle: 'There are orders that contain worklogs belonging to the Jira project. Remove these from your orders before continuing.',
    icon: require('../../../assets/icons/svg/ic_error_outline.svg'),
    options: new ToastOptions(3000, 'not-allowed-toast')
  }),
  PROJECT_NOT_FOUND: new ToastContent({
    title: 'Not found',
    subtitle: 'The project you tried to access was not found.',
    icon: require('../../../assets/icons/svg/ic_error_outline.svg'),
    options: new ToastOptions(3000, 'not-allowed-toast')
  }),
  ORDER_NOT_FOUND: new ToastContent({
    title: 'Not found',
    subtitle: 'The order you tried to access was not found.',
    icon: require('../../../assets/icons/svg/ic_error_outline.svg'),
    options: new ToastOptions(3000, 'not-allowed-toast')
  }),

  /** Order **/
  ORDER_CREATED: (article, color, type, title) => new ToastContent({
    title: 'Success',
    subtitle: `${article} <span class="success-${color}">${type}</span> named <strong>'${title}'</strong> has been added to your orders.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  ORDER_EDITED: (isComplete) => new ToastContent({
    title: 'Success',
    subtitle: isComplete ? 'The order has been moved to this project\'s completed orders.' : 'The order has been edited.',
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),

  /** Logs **/
  PRESET_EDITED: new ToastContent({
    title: 'Success',
    subtitle: 'The export preset has been edited',
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  RATIO_SINGLE_EDITED: (ratio) => new ToastContent({
    title: 'Success',
    subtitle: `The log's ratio has been changed to ${ratio}`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  LOGS_ASSIGNED: (logCount, order, projectId) => {
    const {orderType, title, id} = order;
    const linkText = `<span class="success-${orderType.color}">${orderType.type}</span> order '<strong>${title}</strong>'`;
    const routerLink = `<a href="${router.resolve({
      name: ROUTE_NAMES_ORDER.ORDER_DETAILS,
      params: {projectId, orderId: id}
    }).href}">${linkText}</a>`;
    return new ToastContent({
      title: 'Success',
      subtitle: `${logCount} ${pluralizeRegularString(logCount, 'log')} have been assigned to the <br/> ${routerLink}.`,
      icon: require('../../../assets/icons/svg/ic_check_success.svg'),
      options: new ToastOptions(3000)
    })
  },
  LOGS_UNASSIGNED: new ToastContent({
    title: 'Success',
    subtitle: 'The logs have been un-assigned from the order.',
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  RATIO_UPDATED: (logCount, ratio) => new ToastContent({
    title: 'Success',
    subtitle: `The <strong>ratio</strong> on ${logCount} logs has been changed to <strong>${ratio}</strong>.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  MARKED_PROCESSED: (logCount, projectId) => {
    const subtitle = `<a href="${router.resolve({
      name: ROUTE_NAMES_ORDER.ORDER_PROCESSED,
      params: {projectId}
    }).href}"><strong>${logCount} ${pluralizeRegularString(logCount, 'log')}</strong> have been marked as processed.</a>`

    return new ToastContent({
      title: 'Success',
      subtitle,
      icon: require('../../../assets/icons/svg/ic_check_success.svg'),
      options: new ToastOptions(3000)
    })
  },
  EMPLOYEE_EDITED: new ToastContent({
    title: 'Success',
    subtitle: 'The employee has been edited.',
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  COMMENT_EDITED: new ToastContent({
    title: 'Success',
    subtitle: 'The comment has been edited.',
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),


  /** Export **/
  LOGS_EXPORTED: (fileName) => new ToastContent({
    title: 'Success',
    subtitle: `<strong>${fileName}</strong> has been added to your downloads.`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),

  /** Users **/
  USER_ADDED: (name) => new ToastContent({
    title: 'Success',
    subtitle: `<span><strong>${name}</strong> has been added.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  USER_ROLE_UPDATED: (count, role) => new ToastContent({
    title: 'Success',
    subtitle: `<span>The role of <strong>${count}</strong> user(s) has been updated to <strong>${role}</strong>.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  USERS_DELETED: (count) => new ToastContent({
    title: 'Success',
    subtitle: `<span><strong>${count}</strong> User(s) have been deleted.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  USERNAME_EDITED: new ToastContent({
    title: 'Success',
    subtitle: `<span>The username of the user has been edited.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  USER_EMAIL_EDITED: new ToastContent({
    title: 'Success',
    subtitle: `<span>The email of the user has been edited.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
  USER_SINGLE_ROLE_UPDATED: (role) => new ToastContent({
    title: 'Success',
    subtitle: `<span>The role of the user has been updated to <strong>${role}</strong>.</span>`,
    icon: require('../../../assets/icons/svg/ic_check_success.svg'),
    options: new ToastOptions(3000)
  }),
}
