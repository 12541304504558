export default class ToastOptions {

  /**
   * @param {Number | null} autoClose - The amount of ms after which the toast should close.
   * @param {String} wrapperClass - The class of the toast's wrapper.
   */
  constructor(autoClose, wrapperClass) {
    this.autoClose = autoClose;
    this.wrapperClass = wrapperClass;
  }
}
