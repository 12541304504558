<template>
  <div class="basic-date-picker">
    <input type="text"
           ref="dateInput"
           :id="idAttr"
           :name="nameAttr"
           :class="classAttr"
           :placeholder="placeholderAttr"
           :disabled="isDisabled"
           v-model="selectedValue"
           :readonly="isReadOnly"
           @focus="datepicker.show = true"
           class="picker-input"/>
    <div v-if="datepicker.show" class="picker__mask" @click="close"></div>
    <div v-if="datepicker.show" class="picker__frame">
      <div class="picker__warp">
        <div class="picker__box">
          <div class="picker__header">
            <div class="picker__month">
              {{ locale.months[datepicker.month - 1] }}
              <!--              <select v-model="datepicker.month">-->
              <!--                <option-->
              <!--                    v-for="(n, i) in datepicker.monthList"-->
              <!--                    :key="i"-->
              <!--                    :value="n"-->
              <!--                >-->
              <!--                  {{ n }}-->
              <!--                </option>-->
              <!--              </select>-->
            </div>
            <div class="picker__year">
              {{ datepicker.year }}
              <!--              <select v-model="datepicker.year">-->
              <!--                <option-->
              <!--                    v-for="(val, i) in datepicker.years"-->
              <!--                    :key="i"-->
              <!--                    :value="val"-->
              <!--                >-->
              <!--                  {{ val }}-->
              <!--                </option>-->
              <!--              </select>-->
            </div>
            <div class="picker__nav--prev" @click="prevMonth">
              <img src="../../assets/icons/svg/ic_back_active_dark_grey.svg" alt=""/>
            </div>
            <div class="picker__nav--next" @click="nextMonth">
              <img src="../../assets/icons/svg/ic_next_active_dark_grey.svg" alt=""/>
            </div>
          </div>
          <table class="picker__table">
            <thead>
            <tr>
              <th
                  v-for="(val, i) in locale.weekday"
                  :key="i"
                  class="picker__weekday"
                  :class="{ picker__weekend: i == 0 || i == 6 }"
              >
                {{ val }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, i) in datepicker.days" :key="i">
              <td role="presentation" v-for="(val, j) in row" :key="j">
                <div
                    class="picker__day"
                    :class="{
                      'picker__day--outfocus': val.month != datepicker.month,
                      'picker__day--infocus': val.month == datepicker.month,
                      'picker__day--today': val.isToday,
                      'picker__day--disabled': val.isDisabled,
                      'picker__day--selected picker__day--highlighted':
                        selectedValue == val.dateString,
                    }"
                    @click="val.isDisabled ? false : select(val.dateString)"
                >
                  {{ val.day }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="picker__footer">
            <button
                class="picker__button--today"
                type="button"
                @click="selectToday"
            >
              {{ locale.todayBtn }}
            </button>
            <!--            <button class="picker__button&#45;&#45;clear" type="button" @click="clear">-->
            <!--              {{ locale.clearBtn }}-->
            <!--            </button>-->
            <!--            <button class="picker__button&#45;&#45;close" type="button" @click="close">-->
            <!--              {{ locale.closeBtn }}-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/** here keep it for Vue 3 **/
// import {defineComponent, ref, reactive, computed, watch} from "vue";
import {defineComponent, ref, reactive, computed, watch} from "@vue/composition-api";

export default defineComponent({
  name: "BasicDatePicker",
  props: {
    idAttr: {
      type: String,
    },
    nameAttr: {
      type: String,
    },
    classAttr: {
      type: String,
    },
    placeholderAttr: {
      type: String,
    },
    valueAttr: {
      type: String,
      default: "",
    },
    yearMinus: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: "01-01-1920",
    },
    to: {
      type: String,
      default: "01-01-2050",
    },
    disabledDate: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    locale: {
      type: Object,
      default: () => {
        return {
          format: "{1}-{2}-{3}",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        };
      },
    },
  },
  setup(props, {emit}) {
    let dateFormatRex = /^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/;
    // let dateFormatRex = /^[0-9]{1,4}\/([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|[12][0-9]|3[01])$/;
    const getDateString = (dateObj, hasMinus) => {
      let yyyy = dateObj.getFullYear();
      if (hasMinus) {
        yyyy = parseInt(yyyy) - parseInt(props.yearMinus);
      }
      let mm = dateObj.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      let dd = dateObj.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }

      return dd + "-" + mm + "-" + yyyy;
      // return yyyy + "/" + mm + "/" + dd;
    };
    const selectedValue = ref("");
    const datepicker = reactive({
      show: false,
      hasRange: computed(() => {
        let result = false;
        if (props.from && props.to && dateFormatRex.test(props.from) && dateFormatRex.test(props.to)) {
          result = true;
        }
        return result;
      }),
      year: 2020,
      years: computed(() => {
        let yearList = [];
        for (let i = datepicker.year - 10; i < datepicker.year + 10; i++) {
          if (datepicker.hasRange) {
            let fromDate = new Date(formatIntoYMD(props.from));
            let toDate = new Date(formatIntoYMD(props.to));
            if (i < fromDate.getFullYear()) {
              continue;
            }
            if (i > toDate.getFullYear()) {
              continue;
            }
          }
          yearList.push(i);
        }
        return yearList;
      }),
      month: 1,
      monthList: computed(() => {
        let result = [];
        for (let i = 1; i <= 12; i++) {
          if (datepicker.hasRange) {
            let fromDate = new Date(formatIntoYMD(props.from));
            let toDate = new Date(formatIntoYMD(props.to));
            if (
                datepicker.year == fromDate.getFullYear() &&
                i < fromDate.getMonth() + 1
            ) {
              if (datepicker.month <= i) {
                datepicker.month = fromDate.getMonth() + 1;
              }
              continue;
            }
            if (
                datepicker.year == toDate.getFullYear() &&
                i > toDate.getMonth() + 1
            ) {
              if (datepicker.month >= i) {
                datepicker.month = toDate.getMonth() + 1;
              }
              continue;
            }
          }
          result.push(i);
        }
        return result;
      }),
      days: computed(() => {
        let year = parseInt(datepicker.year) + parseInt(props.yearMinus);
        let month = datepicker.month;
        let startDate = new Date(year + "/" + month + "/1");
        let lastDate = new Date(year, month, 0);
        let startDateWeekday = startDate.getDay();
        let lastDateWeekday = lastDate.getDay();
        if (startDateWeekday != 0) {
          startDate.setDate(startDate.getDate() - startDateWeekday);
        }
        if (lastDateWeekday != 6) {
          let padding = 6 - lastDateWeekday;
          lastDate.setDate(lastDate.getDate() + padding);
        }
        let days = [];
        let row = [];
        let today = getDateString(new Date(), true);
        let isDisabled = false;
        while (startDate.getTime() - lastDate.getTime() <= 0) {
          isDisabled = false;
          let yyyy = parseInt(startDate.getFullYear()) - parseInt(props.yearMinus);
          let mm = startDate.getMonth() + 1;
          let dd = startDate.getDate();

          if (datepicker.hasRange) {
            let fromDate = formatIntoYMD(props.from);
            let toDate = formatIntoYMD(props.to);
            if (
                yyyy < fromDate.getFullYear() ||
                (yyyy == fromDate.getFullYear() && mm < toDate.getMonth() + 1) ||
                (yyyy == fromDate.getFullYear() &&
                    mm == fromDate.getMonth() + 1 &&
                    dd < fromDate.getDate())
            ) {
              isDisabled = true;
            }
            if (
                yyyy > toDate.getFullYear() ||
                (yyyy == toDate.getFullYear() && mm > toDate.getMonth() + 1) ||
                (yyyy == toDate.getFullYear() &&
                    mm == toDate.getMonth() + 1 &&
                    dd > toDate.getDate())
            ) {
              isDisabled = true;
            }
          }
          if (!isDisabled && props.disabledDate.length > 0) {
            isDisabled = props.disabledDate.indexOf(getDateString(startDate, true)) >= 0;
          }
          let dateObj = {
            year: yyyy,
            month: mm,
            day: dd,
            weekday: startDate.getDay(),
            dateString: getDateString(startDate, true),
            isToday: getDateString(startDate, true) == today,
            isDisabled: isDisabled,
          };
          row.push(dateObj);
          if (row.length >= 7) {
            days.push(row);
            row = [];
          }
          startDate.setDate(startDate.getDate() + 1);
        }
        return days;
      }),
    });

    function formatIntoYMD(dt) {
      // Format the date into yyyy/mm/dd.
      const splitDate = dt.split("-");
      return new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);
    }
    const dateInput = ref(null);
    watch(selectedValue, (value, prevValue) => {
      if (value != "") {
        let result = "";
        // Format the date into yyyy/mm/dd.
        const formattedDate = formatIntoYMD(value);

        if (dateFormatRex.test(value)) {
          result = getDateString(formattedDate, false);
        } else {
          result = prevValue;
        }
        selectedValue.value = result;
      }
      emit("value-logs", value);
      // target.value updates a tiny bit more slowly than v-model value
      setInputValueChangeInterval(value);
    });
    const setInputValueChangeInterval = (value) => {
      if (value !== dateInput.value.value) {
        const interval = setInterval(() => {
          if (value === dateInput.value.value) {
            emit("input-logs", {target: dateInput.value});
            clearInterval(interval);
          }
        }, 10);
        return;
      }
      emit("input-logs", {target: dateInput.value});
    };
    watch(() => datepicker.show, (state) => {
      if (state) {
        let thisMonth = new Date();
        if (selectedValue.value) {
          thisMonth = new Date(formatIntoYMD(selectedValue.value));
        }
        datepicker.year = thisMonth.getFullYear();
        datepicker.month = thisMonth.getMonth() + 1;
      }
    });

    const prevMonth = () => {
      let tempPrevYear = datepicker.month == 1 ? datepicker.year - 1 : datepicker.year;
      let tempPrevMonth = datepicker.month == 1 ? 12 : datepicker.month - 1;
      if (datepicker.hasRange) {
        let fromDate = new Date(formatIntoYMD(props.from));
        if (tempPrevYear < fromDate.getFullYear()) {
          return false;
        }
        if (
            tempPrevYear == fromDate.getFullYear() &&
            tempPrevMonth < fromDate.getMonth() + 1
        ) {
          return false;
        }
      }
      datepicker.year = tempPrevYear;
      datepicker.month = tempPrevMonth;
    };

    const nextMonth = () => {
      let tempNextYear = datepicker.month == 12 ? datepicker.year + 1 : datepicker.year;
      let tempNextMonth = datepicker.month == 12 ? 1 : datepicker.month + 1;
      if (datepicker.hasRange) {
        let toDate = new Date(formatIntoYMD(props.to));
        if (tempNextYear > toDate.getFullYear()) {
          return false;
        }
        if (
            tempNextYear == toDate.getFullYear() &&
            tempNextMonth > toDate.getMonth() + 1
        ) {
          return false;
        }
      }
      datepicker.year = tempNextYear;
      datepicker.month = tempNextMonth;
    };
    const selectToday = () => {
      let today = new Date();
      let tempYear = parseInt(today.getFullYear()) - parseInt(props.yearMinus);
      let tempMonth = today.getMonth() + 1;
      if (datepicker.hasRange) {
        let fromDate = new Date(formatIntoYMD(props.from));
        let toDate = new Date(formatIntoYMD(props.to));
        if (today < fromDate) {
          tempYear = fromDate.getFullYear();
          tempMonth = fromDate.getMonth() + 1;
          today = fromDate;
        }
        if (today > toDate) {
          tempYear = toDate.getFullYear();
          tempMonth = toDate.getMonth() + 1;
          today = toDate;
        }
      }
      datepicker.year = tempYear;
      datepicker.month = tempMonth;
      selectedValue.value = getDateString(today, true);
      datepicker.show = false;
    };
    const clear = () => {
      selectedValue.value = "";
      datepicker.show = false;
    };
    const close = () => {
      datepicker.show = false;
    };
    const select = (value) => {
      selectedValue.value = value;
      datepicker.show = false;
    };

    select(props.valueAttr);

    watch(() => props.valueAttr, (newValue) => {
      if (newValue !== selectedValue.value) {
        select(newValue);
      }
    });

    return {
      selectedValue,
      datepicker,
      prevMonth,
      nextMonth,
      selectToday,
      select,
      clear,
      close,
      dateInput
    };
  },
});
</script>

<style scoped lang="scss">

.basic-date-picker {
  font-family: "Open Sans";
}

// Input
.picker-input {
  border: 1px solid #E4E4E4;
  font-family: "Open Sans";
  font-size: 16px;
  height: 42px;
  padding: 9px 16px;

  &::placeholder {
    color: #D0D0D0;
    font-family: "Open Sans";
  }

  &:focus {
    border-color: var(--black-light);
  }
}

.picker__mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #ffffff00;
  z-index: 10000;
  top: 0;
  left: 0;
}

.picker__frame {
  position: absolute;
  z-index: 10001;
}

.picker__warp {
  border: 1px solid #ABACAD;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);

}

.picker__box {
  padding: 20px;
}

.picker__header {
  position: relative;
  padding-bottom: 10px;
}

.picker__header,
.picker__table {
  text-align: center;
}

.picker__footer {
  margin-top: 23px;
  text-align: end;
}

.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.picker__nav--next,
.picker__nav--prev {
  position: absolute;
  padding: 0.5em 1.25em;
  box-sizing: content-box;
  top: -0.25em;

  img {
    width: 20px;
    height: 20px;
  }
}

.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em;
}

.picker__nav--next {
  right: -1em;
  padding-left: 1.25em;
}

@media (min-width: 24.5em) {
  .picker__select--month,
  .picker__select--year {
    margin-top: -0.5em;
  }

  .picker__nav--next,
  .picker__nav--prev {
    top: -0.33em;
  }

  .picker__nav--prev {
    padding-right: 1.5em;
  }

  .picker__nav--next {
    padding-left: 1.5em;
  }
}

.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000;
}

.picker__nav--next:hover,
.picker__nav--prev:hover {
  cursor: pointer;
  background: #F4F6F8;
}

.picker--focused .picker__day--highlighted,
.picker__day--highlighted:hover,
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  color: white;
  cursor: pointer;
  background: #383F4F;
}

.picker__day--disabled:before {
  border-top-color: #aaa;
}

.picker__day--outfocus {
  color: #ddd;
}

.picker--focused .picker__day--selected,
.picker__day--selected,
.picker__day--selected:hover {
  border: 1px solid #E65541;
  border-radius: 2px;
  color: #E65541;
  font-weight: bold;
}

.picker__day--selected:hover {
  border: none;
  color: white;
}

.picker--focused .picker__day--disabled,
.picker__day--disabled,
.picker__day--disabled:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: pointer;
}

.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbb;
}

// Weekday
.picker__weekday {
  color: #B3B3B3;
  font-size: 10px;
  font-weight: bold;
  padding-bottom: 0.25em;
  text-transform: uppercase;
  width: 14.285714286%;
}

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: 0.5em;
  }
}

.picker__button--clear,
.picker__button--close {
  border: 1px solid #fff;
  background: #fff;
  font-size: 0.8em;
  padding: 0.66em 0;
  font-weight: 700;
  width: 33%;
  display: inline-block;
  vertical-align: bottom;
}

.picker__button--today {
  background-color: white;
  border: 1px solid #E65541;
  border-radius: 4px;
  color: #E65541;
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  vertical-align: bottom;
  width: 58px;
}

.picker__button--clear:hover,
.picker__button--close:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}

.picker__button--today:hover {
  cursor: pointer;
  color: white;
  background: #FF6800;
  border-color: #FF6800;
}

.picker__button--clear:focus,
.picker__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: 0;
}

.picker__button--today:focus {
  background: #FF6800;
  border-color: #FF6800;
  color: white;
  outline: 0;
}

.picker__button--clear:before,
.picker__button--close:before,
.picker__button--today:before {
  position: relative;
  display: inline-block;
  height: 0;
}

//.picker__button--clear:before,
//.picker__button--today:before {
//  content: " ";
//  margin-right: 0.45em;
//}

.picker__button--clear:before {
  top: -0.25em;
  width: 0.66em;
  border-top: 3px solid #e20;
}

.picker__button--close:before {
  content: "\D7";
  top: -0.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: 0.35em;
  color: #777;
}

.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaa;
}

.picker__day {
  padding: 2px 6px;
}

.picker__day--today {
  background: #383F4F;
  border-radius: 2px;
  color: #fff;
  font-weight: bold;
  position: relative;
}

.picker__weekend {
  //color: #e20;
}
</style>
