import {RESET_MODAL_OPTIONS, SET_MODAL_OPTIONS} from "@/store/mutations";
import {computed} from "@vue/composition-api";
import vuexStore from "../store";

export function getModalHelpers() {
  function setModal(content) {
    vuexStore.commit(SET_MODAL_OPTIONS, {modalContent: content, activeModal: content.name});
  }

  function closeModal() {
    vuexStore.commit(RESET_MODAL_OPTIONS);
  }

  const activeModal = computed(() => vuexStore.getters.getActiveModal);

  return {activeModal, setModal, closeModal};
}
