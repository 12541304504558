import {GET_ORDER_TYPES} from "../../store/order/actions";
import vuexStore from "../../store";
import {computed} from "@vue/composition-api";


export default function getOrderHelpers() {
  const store = vuexStore;

  const orderTypes = computed(() => store.getters.getOrderTypes);

  function getOrderTypes() {
    return store.dispatch(GET_ORDER_TYPES);
  }

  return {
    orderTypes,
    getOrderTypes
  }
}